import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ApiService } from './services/api/api.service';
import { ProgressBarInterceptor } from './services/api/progress-bar.interceptor';
import { AuthRedirectService } from './services/auth-redirect.service';
import { AccessKeyGuard } from './guards/access-key.guard';

@NgModule({
  imports: [CommonModule, HttpClientModule],
  providers: [
    ApiService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ProgressBarInterceptor,
      multi: true,
    },
    AuthRedirectService,
    AccessKeyGuard,
  ],
  declarations: [],
  exports: [],
})
export class CoreModule {}
