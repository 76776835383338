import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SignInPageGuard } from './pages/sign-in-page/sign-in-page.guard';
import { ForgotPageGuard } from './pages/forgot-page/forgot-page.guard';

const routes: Routes = [
  {
    path: 'signin',
    loadChildren: () => import('./pages/sign-in-page/sign-in-page.module').then((m) => m.SignInPageModule),
    canActivate: [SignInPageGuard],
  },
  {
    path: 'forgot',
    loadChildren: () => import('./pages/forgot-page/forgot-page.module').then((m) => m.ForgotPageModule),
    canActivate: [ForgotPageGuard],
  },
  {
    path: 'forgot-confirm/:key',
    loadChildren: () =>
      import('./pages/forgot-confirm-page/forgot-confirm-page.module').then((m) => m.ForgotConfirmPageModule),
  },
  {
    path: 'signup-confirm/:key',
    loadChildren: () =>
      import('./pages/sign-up-confirm-page/sign-up-confirm-page.module').then((m) => m.SignUpConfirmPageModule),
  },

  {
    path: 'me',
    loadChildren: () => import('./pages/me-page/me-page.module').then((m) => m.MePageModule),
  },
  {
    path: '**',
    redirectTo: 'signin',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
