import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { IAuthSignInRequest } from './req/IAuthSignInRequest';
import { Observable } from 'rxjs';
import { IAuthSession } from './res/IAuthSession';
import { IAuthSessionsRevokeRequest } from './req/IAuthSessionsRevokeRequest';
import { IAuthPasswordRestoreRequest } from './req/IAuthPasswordRestoreRequest';
import { IAuthPasswordConfirmRequest } from './req/IAuthPasswordConfirmRequest';
import { environment } from '../../../../environments/environment';
import { IAuthSessionMeResponse } from './res/IAuthSessionMeResponse';
import { IAuthCallbackValidateRequest } from './req/IAuthCallbackValidateRequest';
import { map } from 'rxjs/operators';
import { IAuthSignUpConfirmRequest } from './req/IAuthSignUpConfirmRequest';

const API_HOST = environment.apiHost;

@Injectable()
export class ApiService {
  constructor(private readonly http: HttpClient) {}

  postAuthSignIn(data: IAuthSignInRequest): Observable<IAuthSession> {
    return this.http.post<IAuthSession>(`${API_HOST}/api/v1/auth/signin`, data);
  }

  getAuthAccessToken(): Observable<string> {
    return this.http
      .get<{ data: string }>(`${API_HOST}/api/v1/auth/signin/access_token`)
      .pipe(map((data) => data.data));
  }

  postAuthSignUpConfirm(data: IAuthSignUpConfirmRequest): Observable<IAuthSession> {
    return this.http.post<IAuthSession>(`${API_HOST}/api/v1/auth/signup/confirm`, data);
  }

  getAuthSessions(data: any): Observable<IAuthSession[]> {
    return this.http.get<IAuthSession[]>(`${API_HOST}/api/v1/auth/sessions`, { params: data });
  }

  postAuthSessionsRevoke(data: IAuthSessionsRevokeRequest): Observable<void> {
    return this.http.post<void>(`${API_HOST}/api/v1/auth/sessions/revoke`, data);
  }

  getAuthInfoSession(): Observable<IAuthSessionMeResponse> {
    return this.http.get<IAuthSessionMeResponse>(`${API_HOST}/api/v1/auth/info/session`);
  }

  postAuthPasswordRestore(data: IAuthPasswordRestoreRequest) {
    return this.http.post(`${API_HOST}/api/v1/auth/password/restore`, data);
  }

  postAuthPasswordConfirm(data: IAuthPasswordConfirmRequest) {
    return this.http.post(`${API_HOST}/api/v1/auth/password/confirm`, data);
  }

  postAuthCallbackValidate(data: IAuthCallbackValidateRequest) {
    return this.http.post(`${API_HOST}/api/v1/auth/callback/validate`, data);
  }
}
