import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ProgressBarService {
  readonly isShown$ = new BehaviorSubject(false);

  show() {
    this.isShown$.next(true);
  }

  hide() {
    this.isShown$.next(false);
  }
}
