import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { ProgressBarModule } from './shared/progress-bar/progress-bar.module';
import { CoreModule } from './core/core.module';
import { SignInPageGuard } from './pages/sign-in-page/sign-in-page.guard';
import { ForgotPageGuard } from './pages/forgot-page/forgot-page.guard';

@NgModule({
  declarations: [AppComponent],
  imports: [BrowserModule, BrowserAnimationsModule, AppRoutingModule, ProgressBarModule, CoreModule],
  providers: [SignInPageGuard, ForgotPageGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
