import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ApiService } from '../../core/services/api/api.service';
import { catchError, switchMap } from 'rxjs/operators';
import JwtDecode from 'jwt-decode';
import { AuthRedirectService } from '../../core/services/auth-redirect.service';

@Injectable()
export class SignInPageGuard implements CanActivate {
  constructor(private readonly apiService: ApiService, private readonly authRedirectService: AuthRedirectService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.apiService.getAuthAccessToken().pipe(
      switchMap((data) => {
        const token = JwtDecode(data) as any;
        this.authRedirectService.redirect(token.scope);
        return of(false);
      }),
      catchError((err) => {
        return of(true);
      }),
    );
  }
}
