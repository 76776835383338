import { Injectable } from '@angular/core';
import { ApiService } from './api/api.service';

@Injectable()
export class AuthRedirectService {
  constructor(private readonly apiService: ApiService) {}

  redirect(scope: string[], callbackUrl?: string) {
    if (callbackUrl) {
      this.apiService.postAuthCallbackValidate({ callback: callbackUrl }).subscribe(
        () => {
          window.location.href = callbackUrl;
        },
        (err) => {
          this.decideByScope(scope);
        },
      );
    } else {
      this.decideByScope(scope);
    }
  }

  private decideByScope(scope: string[]) {
    if (scope.includes('live') || scope.includes('new-live')) {
      window.location.href = 'https://live.quantapower.com';
      return;
    }
    if (scope.includes('dev-live')) {
      window.location.href = 'https://dev.live.quantapower.com';
      return;
    }
    if (scope.includes('libstar')) {
      window.location.href = 'https://libstar.quantapower.com';
      return;
    }
    window.location.href = 'https://live.quantapower.com';
  }
}
